import {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import {LayoutComponent} from './common/LayoutComponent';
import {PATHS} from './paths';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {API, API_CALL} from "./api";
import {isSuccess} from "./common";

import {registerLocale, setDefaultLocale} from "react-datepicker";
import bg from 'date-fns/locale/bg';
import HomePage from "./pages/homepage/HomePage";
import {LoginPage} from "./pages/LoginPage";
import {ConfigPage} from "./pages/config/ConfigPage";

import "react-datepicker/dist/react-datepicker.css";
import {StorePage} from "./pages/store/StorePage";
import {DailyReportPage} from "./pages/daily-report/DailyReportPage";
import {InvoicesPage} from "./pages/invoices/InvoicesPage";
import {RecordsPage} from "./pages/records/RecordsPage";
import {ReportsPage} from "./pages/reports/ReportsPage";
import DentalClinicPage from "./pages/dental-clinic/DentalClinicPage";
import {SelectClinicPage} from "./pages/SelectClinicPage";

toast.configure({
    position: toast.POSITION.BOTTOM_CENTER
});

registerLocale('bg', bg);
setDefaultLocale('bg');


const App = () => {
    const [user, setUser] = useState(null);

    const isLogin = window.location.href.indexOf(PATHS.login) > -1

    useEffect(() => {
        if (!API.user && !isLogin) {
            API.getUserData((apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    API.user = apiCall.data.data.user;
                    setUser(apiCall.data.data.user);
                }
            })
        }
    })


    let currentDomain = window.location.hostname;
    console.log(currentDomain);

    if ("vandev.vivisoft-bg.com" === currentDomain) {
        return <SelectClinicPage/>
    }

    if (currentDomain === "localhost") {
        currentDomain = "vandev-varna.vivisoft-bg.com";
    }

    API.site = `https://${currentDomain}/`;
    API.host = `https://${currentDomain}/api/api/`;
    API.clinic = currentDomain === "vandev-varna.vivisoft-bg.com" ? 1 : 0;


    if (user || isLogin) return (
        <Router>
            <LayoutComponent>
                <Switch>
                    <Route exact path={PATHS.home}>
                        <HomePage/>
                    </Route>
                    <Route exact path={PATHS.login}>
                        <LoginPage/>
                    </Route>
                    <Route exact path={PATHS.recordsPlovdiv}>
                        <RecordsPage clinicIndex={0}/>
                    </Route>
                    <Route exact path={PATHS.recordsVarna}>
                        <RecordsPage clinicIndex={1}/>
                    </Route>
                    <Route exact path={PATHS.dental}>
                        <DentalClinicPage/>
                    </Route>
                    <Route exact path={PATHS.dailyReport}>
                        <DailyReportPage/>
                    </Route>
                    <Route exact path={PATHS.store}>
                        <StorePage/>
                    </Route>
                    <Route exact path={PATHS.reports + "/estetic"}>
                        <ReportsPage mode={"estetic"}/>
                    </Route>
                    <Route exact path={PATHS.reports + "/dental"}>
                        <ReportsPage mode={"dental"}/>
                    </Route>
                    <Route exact path={PATHS.invoices}>
                        <InvoicesPage/>
                    </Route>
                    <Route exact path={PATHS.config}>
                        <ConfigPage/>
                    </Route>
                </Switch>
            </LayoutComponent>
        </Router>
    ); else return <></>;
}

export default App;
