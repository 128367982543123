import ModalHeader from "react-bootstrap/ModalHeader";
import {
    Button,
    CloseButton,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalTitle,
    Row,
    Spinner, Tab, Table, Tabs
} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faGift, faPlus,} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    showMsgInToast,
} from "../../common";
import VouchersDialogList from "./VouchersDialogList";
import {API} from "../../api";
import VouchersDialogEdit from "./VouchersDialogEdit";
import {RecordType} from "../records/RecordDetailsPage";

export interface VaucherDialogProps {
    zIndex?: number;
    type?: VoucherOrPacketType;
    recordType?: RecordType;
    recordItemId?: number;
    recordItemQty: number;
    onSelect?: (c: VoucherType) => void;
    onClose: VoidFunction;
}

export type VoucherItemType = {
    itemGroup: VoucherItemGroupType;
    itemId: number;
    itemName: string;
    usedOn: Date | null;
}

export type VoucherType = {
    id?: number;
    voucherType: "VOUCHER" | "PACKET";
    number: string;
    date: Date;
    buyer?: string;
    recipient?: string;
    amount: number;
    usedAmount: number;
    payedByCash: number;
    payedByCard: number;
    paidByVoucher: number;
    voucherId?: number;
    voucher?: VoucherType;
    isValid: boolean;
    items: VoucherItemsType;
}
export type VouchersType = VoucherType[];
export type VoucherItemsType = VoucherItemType[];
export type VoucherItemGroupType = "Процедура" | "Филър" | "Вливка";
export type VoucherOrPacketType = "VOUCHER" | "PACKET";

const VouchersDialog: React.FC<VaucherDialogProps> = ({
                                                          type,
                                                          recordType, recordItemId, recordItemQty,
                                                          zIndex, onSelect, onClose
                                                      }) => {
    const [working, setWorking] = useState(false);
    const [viewMode, setViewMode] = useState<"LIST" | "EDIT" | "DELETE">("LIST");
    const [voucherType, setVoucherType] = useState<VoucherOrPacketType>(type || "VOUCHER");
    const [data, setData] = useState<VouchersType>([]);
    const [selectedVoucher, setSelectedVoucher] = useState<VoucherType | null>(null);

    const countItems = (value: VoucherType, recordItemId: number) => {
        let cnt = 0;
        for (const item of value.items) {
            if (item.itemId === recordItemId && !item.usedOn) cnt++;
        }

        return cnt;
    }
    const loadVouchers = () => {
        API.loadVouchers(apiCall => {
            setWorking(isLoading(apiCall));
            if (isSuccess(apiCall)) {
                const rawData: VouchersType = apiCall.data.data.vouchers.map((v: any) => {
                    const voucher: VoucherType = {
                        ...v,
                        amount: Number(v.amount),
                        paidByVoucher: Number(v.paidByVoucher),
                        payedByCard: Number(v.payedByCard),
                        payedByCash: Number(v.payedByCash),
                        usedAmount: Number(v.usedAmount),
                    }

                    return voucher;
                });

                if (recordItemId && recordItemQty > 1) {
                    setData(rawData.filter(value => countItems(value, recordItemId) >= recordItemQty));
                } else {
                    setData(rawData);
                }
            }
            if (isError(apiCall)) {
                setData([]);
                showErrorsInToast(apiCall, "Възникна грешка");
            }
        }, voucherType, recordType, recordItemId);
    }

    const saveVoucher = (newVoucher: VoucherType) => {
        API.saveVoucher(apiCall => {
            setWorking(isLoading(apiCall));
            if (isSuccess(apiCall)) {
                setSelectedVoucher(null);
                setViewMode("LIST");
                showMsgInToast("Записа е успешен!");
                loadVouchers();
            }
            if (isError(apiCall)) {
                showErrorsInToast(apiCall, "Възникна грешка");
            }
        }, newVoucher);
    };

    const addNew = () => {
        API.createVoucher(apiCall => {
            setWorking(isLoading(apiCall));
            if (isSuccess(apiCall)) {
                setSelectedVoucher(apiCall.data.data.newVoucher);
                setViewMode("EDIT");
                loadVouchers();
            }

            if (isError(apiCall)) {
                showErrorsInToast(apiCall, "Възникна грешка");
            }
        }, voucherType);
    }

    const deleteVoucher = (voucher: VoucherType) => {
        API.deleteVoucher(apiCall => {
            setWorking(isLoading(apiCall));
            if (isSuccess(apiCall)) {
                setSelectedVoucher(null);
                setViewMode("LIST");
                showMsgInToast("Изтриванвето е успешно!");
                loadVouchers();
            }
            if (isError(apiCall)) {
                showErrorsInToast(apiCall, "Възникна грешка");
            }
        }, voucher);
    }

    useEffect(() => {
        loadVouchers();
    }, [voucherType]);

    let modalBody;

    console.log(data);

    switch (viewMode) {
        case "LIST":
            modalBody = <VouchersDialogList type={voucherType}
                                            data={data}
                                            onChangeType={newType => {
                                                if (!onSelect) setVoucherType(newType as VoucherOrPacketType)
                                            }}
                                            onSelect={onSelect}
                                            onEdit={voucher => {
                                                setSelectedVoucher(voucher);
                                                setViewMode("EDIT");
                                            }}
                                            onDelete={voucher => deleteVoucher(voucher)}
            />;
            break;
        case "EDIT":
            modalBody = <VouchersDialogEdit voucher={selectedVoucher!} working={working}
                                            onSave={newVoucher => saveVoucher(newVoucher)}
                                            onDone={() => setViewMode("LIST")}/>;
            break;
        case "DELETE":
            modalBody = null;
            break;
    }

    return <Modal show={true} size={"xl"} onHide={() => onClose()}
                  style={{zIndex: zIndex ? zIndex : 1600}}>
        <ModalHeader>
            <ModalTitle>
                <FontAwesomeIcon icon={faGift}/> Ваучери и пакети...
            </ModalTitle>
            <CloseButton onClick={() => onClose()}/>
        </ModalHeader>
        <ModalBody>
            {modalBody}
        </ModalBody>
        {
            viewMode === "LIST" &&
            <ModalFooter>
                {
                    working ? <Col className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                        :
                        <>
                            <Col>
                                <Button variant={"primary"} onClick={addNew}>
                                    <FontAwesomeIcon icon={faPlus}/> Добави
                                </Button>
                            </Col>
                            <Col className={"text-right"}>
                                <Button variant={"secondary"} onClick={() => onClose()}>
                                    <FontAwesomeIcon icon={faCancel}/> Затвори
                                </Button>
                            </Col>
                        </>
                }
            </ModalFooter>
        }
    </Modal>
}

export default VouchersDialog;
