import React, {useState} from "react";
import {PATHS} from "../paths";
import {Link} from "react-router-dom";
import {API, API_CALL} from "../api";
import {isSuccess} from "../common";
import {UserNameComponent} from "./UserNameComponent";
import {
    faChartLine,
    faCogs, faCrown,
    faFileLines, faGift,
    faHome,
    faSignOutAlt, faStore, faTeethOpen
} from "@fortawesome/free-solid-svg-icons";
import {
    Nav,
    Navbar,
    NavDropdown,
} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HeaderInvoicesComponent from "./HeaderInvoicesComponent";
import VouchersDialog from "../pages/vouchers/VouchersDialog";

export function HeaderComponent(props: any) {
    const [showVoucersDialog, setShowVouchersDialog] = useState(false);

    const logout = function () {
        API.getUserLogout(
            (api: API_CALL) => {
                if (isSuccess(api)) {
                    API.user = null;
                    window.location.href = "/login";
                }
            }
        )
    }

    return (<>
            <Navbar expand="lg" className={"w-100 header-bg text-white-50"}>
                <Navbar.Brand>
                    <Link to={PATHS.home + API.getAPITokenAsQuery()} className={"text-dark text-nowrap"}>
                        <img src='/imgs/logo.gif' style={{height: "2em"}}/>
                        <span style={{fontFamily: "monospace", fontSize: "15px", fontStyle: "italic"}}
                              className={"text-white ml-5"}> Лице в хармония!</span>
                    </Link>
                </Navbar.Brand>
                {
                    API.user &&
                    <>
                        <Navbar.Toggle aria-controls="navbarScroll"/>
                        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
                            <Nav
                                className="mr-0 my-2 my-lg-0"
                                navbarScroll
                            >
                                <Nav.Link href={PATHS.home + API.getAPITokenAsQuery()}
                                          className={"text-white-50"}><FontAwesomeIcon icon={faHome}/> Начало</Nav.Link>
                                {[1, 49].some(id => id == API.user?.id) && <></>}

                                <NavDropdown
                                    title={<span className={"text-white-50"}><FontAwesomeIcon icon={faFileLines}/> Досиета</span>}
                                    id="nav-dropdown">
                                    {
                                        API.clinic === 0 &&
                                        <NavDropdown.Item>
                                            <Link className={"text-dark text-decoration-none"}
                                                  to={PATHS.recordsPlovdiv + API.getAPITokenAsQuery()}>
                                                <FontAwesomeIcon icon={faCrown}/> Естетична клиника - Пловдив</Link>
                                        </NavDropdown.Item>
                                    }
                                    {
                                        API.clinic === 1 &&
                                        <NavDropdown.Item>
                                            <Link className={"text-dark text-decoration-none"}
                                                  to={PATHS.recordsVarna + API.getAPITokenAsQuery()}>
                                                <FontAwesomeIcon icon={faCrown}/> Естетична клиника - Варна</Link>
                                        </NavDropdown.Item>
                                    }
                                    {
                                        API.clinic === 0 &&
                                        <NavDropdown.Item>
                                            <Link className={"text-dark text-decoration-none"}
                                                  to={PATHS.dental + API.getAPITokenAsQuery()}>
                                                <FontAwesomeIcon icon={faTeethOpen}/> Дентална клиника - Пловдив</Link>
                                        </NavDropdown.Item>
                                    }
                                    {
                                        API.clinic === 1 &&
                                        <NavDropdown.Item>
                                            <Link className={"text-dark text-decoration-none"}
                                                  to={PATHS.dental + API.getAPITokenAsQuery()}>
                                                <FontAwesomeIcon icon={faTeethOpen}/> Дентална клиника - Варна</Link>
                                        </NavDropdown.Item>
                                    }
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item onClick={() => {
                                        setShowVouchersDialog(true)
                                    }}>
                                        <FontAwesomeIcon icon={faGift}/> Ваучери и пакети
                                    </NavDropdown.Item>
                                </NavDropdown>

                                <Nav.Link href={PATHS.invoices + API.getAPITokenAsQuery()} className={"text-white-50"}>
                                    <HeaderInvoicesComponent/>
                                </Nav.Link>
                                <Nav.Link href={PATHS.store + API.getAPITokenAsQuery()}
                                          className={"text-white-50"}><FontAwesomeIcon
                                    icon={faStore}/> Склад</Nav.Link>


                                <NavDropdown
                                    title={<span className={"text-white-50"}><FontAwesomeIcon icon={faChartLine}/> Отчети</span>}
                                    id="nav-dropdown">
                                    <NavDropdown.Item>
                                        <Link className={"text-dark text-decoration-none"}
                                              to={PATHS.reports + "/estetic" + API.getAPITokenAsQuery()}>
                                            <FontAwesomeIcon icon={faCrown}/> Естетична клиника</Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <Link className={"text-dark text-decoration-none"}
                                              to={PATHS.reports + "/dental" + API.getAPITokenAsQuery()}>
                                            <FontAwesomeIcon icon={faTeethOpen}/> Дентална клиника</Link>
                                    </NavDropdown.Item>
                                </NavDropdown>


                                <Nav.Link href={PATHS.config + API.getAPITokenAsQuery()}
                                          className={"text-white-50"}><FontAwesomeIcon
                                    icon={faCogs}/> Номенклатури</Nav.Link>
                                <Nav.Link href="/" className={"text-white-50"}><FontAwesomeIcon icon={faSignOutAlt}
                                                                                                size="sm"/> Изход</Nav.Link>
                            </Nav>
                            <Navbar.Text>
                                {window.location.href.indexOf(PATHS.login) === -1 && <UserNameComponent/>}
                            </Navbar.Text>
                        </Navbar.Collapse>
                    </>
                }
            </Navbar>

            {
                showVoucersDialog && <VouchersDialog onClose={() => setShowVouchersDialog(false)} recordItemQty={1}/>
            }
        </>
    )
}
